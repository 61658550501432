import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import SEO from "../components/elements/SEO";
import Layout from "../components/layout/Layout";
import SectionList from "../components/sections/SectionList";

const query = graphql`
  query {
    strapiLandingPage {
      sections {
        __typename
        ... on StrapiComponentBlocksSoftwareGrid {
          __typename
          id
          info {
            anchor
            paddingTop
            paddingBottom
            description
            background
            title
          }
          softwareCard {
            software {
              name
              summary
              banner {
                file {
                  publicURL
                }
              }
              features {
                value
              }
              icon {
                file {
                  publicURL
                }
              }
              seo_description
              videos {
                url
              }
              videos_from_file {
                file {
                  publicURL
                }
              }
              pdf {
                file {
                  publicURL
                }
              }
              description
              placeholderImage {
                file {
                  publicURL
                }
              }
            }
            title
            id
          }
        }
        ... on StrapiComponentBlocksTwoColumnHero {
          __typename
          id
          button {
            primary
            outline
            label
            url
          }
          info {
            anchor
            paddingTop
            paddingBottom
            description
            background
            title
          }
          placeholderImage {
            file {
              publicURL
            }
          }
          image {
            file {
              publicURL
              internal {
                mediaType
              }
            }
          }
        }
        ... on StrapiComponentBlocksCardGrid {
          __typename
          id
          blockInfo {
            anchor
            paddingTop
            paddingBottom
            description
            background
            title
          }
          cards {
            title
            description
            icon {
              file {
                publicURL
              }
            }
          }
        }
        ... on StrapiComponentBlocksTextGrid {
          __typename
          id
          values {
            id
            value
          }
          blockInfo {
            anchor
            paddingTop
            paddingBottom
            description
            background
            title
          }
        }
        ... on StrapiComponentBlocksTwoColumnCallToAction {
          __typename
          id
          blockInfo {
            id
            paddingBottom
            paddingTop
            strapiId
            title
            background
            description
            anchor
          }
          action {
            label
            url
          }
          image {
            file {
              publicURL
              internal {
                mediaType
              }
            }
          }
          placeholderImage {
            file {
              publicURL
            }
          }
        }
        ... on StrapiComponentBlocksForm {
          id
          button {
            label
            outline
            primary
            size
            url
          }
          fields {
            label
            options
            placeholder
            required
            errorMessage
            type
          }
          blockInfo {
            anchor
            paddingTop
            paddingBottom
            description
            background
            title
          }
        }
        ... on StrapiComponentBlocksRegionGrid {
          id
          regions {
            description
            email
            icon {
              file {
                publicURL
              }
            }
            phone
            subtitle
            title
            websiteURL
          }
          blockInfo {
            anchor
            paddingTop
            paddingBottom
            description
            background
            title
          }
        }
      }
      pageInfo {
        seoTitle
      }
    }
  }
`;

const IndexPage = () => {
  const { strapiLandingPage } = useStaticQuery(query);
  return (
    <Layout>
      <SEO title={strapiLandingPage.pageInfo.seoTitle} />
      <SectionList sections={strapiLandingPage.sections} />
    </Layout>
  );
};

export default IndexPage;
